import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import { faUserHeadset } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import { useCallback } from "react";
import { NavLink, useLocation } from "react-router-dom";

import styles from "@/components/layout/sidebar/agents/agents.module.css";
import { routes } from "@/routes";
import { Agent } from "@/types/agent";

export const AGENT_ID_PARAM_KEY = "agent-id";

interface Props {
    agents: Agent[];
}

const Agents = ({ agents }: Props) => {
    const location = useLocation();

    const isActive = useCallback(
        (agentId: string) => {
            const searchParams = new URLSearchParams(location.search);
            const activeAgentId = searchParams.get(AGENT_ID_PARAM_KEY);
            return activeAgentId === agentId;
        },
        [location],
    );

    return (
        <>
            {agents.map((agent) => (
                <WdsSidebar.Item
                    asChild
                    key={agent.id}
                    className={clsx(styles.agent_item, { [styles.agent_item_active]: isActive(agent.id) })}
                >
                    <NavLink to={routes.home.path + `?${AGENT_ID_PARAM_KEY}=${agent.id}`}>
                        <WdsSidebar.Item.Icon className={clsx({ [styles.active]: isActive(agent.id) })}>
                            <FontAwesomeIcon
                                className={clsx(styles.agent_item_icon, {
                                    [styles.agent_item_icon_active]: isActive(agent.id),
                                })}
                                icon={faUserHeadset}
                            />
                        </WdsSidebar.Item.Icon>
                        <WdsSidebar.Item.Content
                            className={clsx(styles.agent_item_content, {
                                [styles.agent_item_content_active]: isActive(agent.id),
                            })}
                        >
                            {agent.name}
                        </WdsSidebar.Item.Content>
                    </NavLink>
                </WdsSidebar.Item>
            ))}
        </>
    );
};
export { Agents };
