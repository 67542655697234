import { api } from "@/store/api";
import { Chat } from "@/types/chat";
import { ChatWithMessages } from "@/types/chat-with-messages";
import { PostChatMessageRequestBody } from "@/types/post-chat-message-request-body";
import { PostChatMessageResponse } from "@/types/post-chat-message-response";
import { PostChatRequestBody } from "@/types/post-chat-request-body";
import { PostChatResponse } from "@/types/post-chat-response";

const ENDPOINT_PREFIX = "chat";

const CHAT_LIST_CACHE_TYPE = "Chat";

export const chatApi = api.enhanceEndpoints({ addTagTypes: [CHAT_LIST_CACHE_TYPE] }).injectEndpoints({
    endpoints: (builder) => ({
        postChat: builder.mutation<PostChatResponse, PostChatRequestBody>({
            query: (body) => {
                return {
                    url: ENDPOINT_PREFIX,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: [{ type: CHAT_LIST_CACHE_TYPE }],
        }),
        getChats: builder.query<Chat[], void>({
            query: () => {
                return {
                    url: ENDPOINT_PREFIX,
                    method: "GET",
                };
            },
            providesTags: [{ type: CHAT_LIST_CACHE_TYPE }],
        }),
        getChatDetail: builder.query<ChatWithMessages, string>({
            query: (sessionId) => {
                return {
                    url: `${ENDPOINT_PREFIX}/${sessionId}`,
                    method: "GET",
                };
            },
        }),
        syncChatTitle: builder.mutation<ChatWithMessages, string>({
            query: (sessionId) => ({
                url: `${ENDPOINT_PREFIX}/${sessionId}`,
                method: "GET",
            }),
            async onQueryStarted(sessionId, { dispatch, queryFulfilled }) {
                try {
                    // Await the API call
                    const { data } = await queryFulfilled;

                    // Update `getChatDetail` cache (only the title field)
                    dispatch(
                        chatApi.util.updateQueryData("getChatDetail", sessionId, (draft) => {
                            draft.title = data.title;
                        }),
                    );

                    // Update `getChats` cache (only the title for the relevant chat)
                    dispatch(
                        chatApi.util.updateQueryData("getChats", undefined, (draft) => {
                            const chatIndex = draft.findIndex((chat) => chat.chatSessionId === sessionId);
                            if (chatIndex !== -1) {
                                draft[chatIndex].title = data.title;
                            }
                        }),
                    );
                } catch (error) {
                    console.error("Error updating chat title:", error);
                }
            },
        }),
        deleteChat: builder.mutation<void, string>({
            query: (sessionId) => {
                return {
                    url: `${ENDPOINT_PREFIX}/${sessionId}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: [{ type: CHAT_LIST_CACHE_TYPE }],
        }),
        postChatMessage: builder.mutation<
            PostChatMessageResponse,
            {
                sessionId: string;
                body: PostChatMessageRequestBody;
            }
        >({
            query: ({ sessionId, body }) => {
                return {
                    url: `${ENDPOINT_PREFIX}/${sessionId}/message/text`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
});

export const {
    useDeleteChatMutation,
    useGetChatDetailQuery,
    useGetChatsQuery,
    usePostChatMessageMutation,
    usePostChatMutation,
    useSyncChatTitleMutation,
} = chatApi;
