import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import { Typography } from "@datamole/wds-component-typography";
import { faMessage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

import styles from "@/components/layout/sidebar/chat-history/chat-history.module.css";
import { generatePath, routes } from "@/routes";
import { useGetAgentsQuery } from "@/store/services/agent.service";
import { Chat } from "@/types/chat";
import { getChatTitle } from "@/utils/get-chat-title";

interface Props {
    chatHistory: Chat[];
}

export const ChatHistory = ({ chatHistory }: Props) => {
    const { data: agents } = useGetAgentsQuery();

    return (
        <>
            {chatHistory.map((chat) => {
                const agentId = chat.agentId;
                const agentName = agents?.find((agent) => agent.id === agentId)?.name;

                return (
                    <WdsSidebar.Item asChild key={chat.chatSessionId}>
                        <NavLink
                            to={generatePath(routes.chat.path, {
                                sessionId: chat.chatSessionId,
                            })}
                        >
                            <WdsSidebar.Item.Icon>
                                <FontAwesomeIcon icon={faMessage} />
                            </WdsSidebar.Item.Icon>
                            <WdsSidebar.Item.Content>
                                <div className={styles.chat_history_item}>
                                    <div>{getChatTitle(chat)}</div>
                                    {agentName && (
                                        <Typography variant={"xs"} colorShade={"lighter"}>
                                            {agentName}
                                        </Typography>
                                    )}
                                </div>
                            </WdsSidebar.Item.Content>
                        </NavLink>
                    </WdsSidebar.Item>
                );
            })}
        </>
    );
};
