import { api } from "@/store/api";
import { Agent } from "@/types/agent";

const ENDPOINT_PREFIX = "agent";

export const agentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
        getAgents: builder.query<Agent[], void>({
            query: () => {
                return {
                    url: ENDPOINT_PREFIX,
                    method: "GET",
                };
            },
        }),
    }),
});

export const { useGetAgentsQuery } = agentApi;
