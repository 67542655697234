import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { AuthLoading } from "@/components/layout/auth-loading";
import styles from "@/components/layout/layout.module.css";
import { Sidebar } from "@/components/layout/sidebar";
import { useAppSelector } from "@/store";

export const Layout = () => {
    const accessToken = useAppSelector((state) => state.auth.accessToken);

    if (!accessToken) {
        return <AuthLoading />;
    }

    return (
        <div className={styles.layout}>
            <Sidebar />
            <div className={styles.content}>
                <Suspense>
                    <Outlet />
                </Suspense>
            </div>
        </div>
    );
};
