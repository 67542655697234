import { Chat } from "@/types/chat";
import { ChatWithMessages } from "@/types/chat-with-messages";

export const getChatTitle = (chat?: Chat | ChatWithMessages) => {
    if (!chat) return "Chat with R&D Driver";

    const chatHash = chat.chatSessionId.substring(chat.chatSessionId.length - 6);

    return chat.title ? chat.title : `Chat ${chatHash}`;
};
