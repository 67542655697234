import styles from "@/components/layout/auth-loading/auth-loading.module.css";
import { Spinner } from "@/components/spinner";

export const AuthLoading = () => {
    return (
        <div className={styles.auth_loading}>
            <Spinner />
        </div>
    );
};
