import { Sidebar as WdsSidebar } from "@datamole/wds-component-sidebar";
import { useMemo } from "react";

import { Agents } from "@/components/layout/sidebar/agents";
import { ChatHistory } from "@/components/layout/sidebar/chat-history";
import { Header } from "@/components/layout/sidebar/header";
import { SidebarItem } from "@/components/layout/sidebar/sidebar-item";
import { sidebarItems } from "@/components/layout/sidebar/sidebar-items";
import styles from "@/components/layout/sidebar/sidebar.module.css";
import { UserMenu } from "@/components/layout/sidebar/user-menu/user-menu";
import { useGetAgentsQuery } from "@/store/services/agent.service";
import { useGetChatsQuery } from "@/store/services/chat.service";

export const Sidebar = () => {
    const { data: chatHistory = [], error: chatHistoryError } = useGetChatsQuery();
    const { data: agents = [], error: agentsError } = useGetAgentsQuery();

    if (chatHistoryError) {
        // TODO: handle error properly
        console.error("Failed to fetch chat history", chatHistoryError);
    }

    if (agentsError) {
        // TODO: handle error properly
        console.error("Failed to fetch agents", agentsError);
    }

    const sortedChatHistory = useMemo(() => {
        return [...chatHistory].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    }, [chatHistory]);

    return (
        <WdsSidebar className={styles.sidebar}>
            <WdsSidebar.Content>
                <Header />

                <SidebarItem {...sidebarItems.home} />

                <Agents agents={agents} />

                <SidebarItem {...sidebarItems.trialEvents} />

                <SidebarItem {...sidebarItems.scientificTestingCalculate} />

                {chatHistory.length > 0 && (
                    <>
                        <WdsSidebar.Subtitle>Chat History</WdsSidebar.Subtitle>
                        <ChatHistory chatHistory={sortedChatHistory} />
                    </>
                )}
            </WdsSidebar.Content>
            <WdsSidebar.Content>
                <UserMenu />
            </WdsSidebar.Content>
        </WdsSidebar>
    );
};
